import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'

import Layout from '../components/layout'
import SEO from '../components/seo'

const title = 'Fairway Tales Podcast'

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media(max-width: 575.98px) {
    flex-direction: column;
    align-items: center;
  }
`

const SubscribeLink = styled.a`
  margin-right: .5rem;
  @media(max-width: 575.98px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`
const EpisodeNumber = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  color: #02b1e7;
`

export const AudioPlayer = ({ length, idx, id }) =>
  length - 1 === idx
    ? <iframe src={`https://player.blubrry.com/id/42696638/#time-0&darkOrLight-Light&shownotes-ffffff&shownotesBackground-bbd77d&download-ffffff&downloadBackground-02b1e7&subscribe-ffffff&subscribeBackground-3dc0b8&share-ffffff&shareBackground-0082ce`} scrolling='no' width='100%' height='138px' frameBorder='0' title={idx} />
    : <iframe src={`https://player.blubrry.com/id/${id}/#time-0&darkOrLight-Light&shownotes-ffffff&shownotesBackground-bbd77d&download-ffffff&downloadBackground-02b1e7&subscribe-ffffff&subscribeBackground-3dc0b8&share-ffffff&shareBackground-0082ce`} scrolling='no' width='100%' height='138px' frameBorder='0' title={idx} />

export const Card = ({ data: { node }, idx, length }) => {
  const id = node.link.split('/')[4] || ''
  return (
    <div class='p-4 my-4 card border-0'>
      <div className='d-flex align-items-baseline mb-2'>
        <EpisodeNumber>{length - idx}</EpisodeNumber>
        <h3 className='text-navy mb-0 ml-2'>{node.title}</h3>
      </div>
      <p className='text-muted'>
        {moment(node.date).format('MMMM D, Y')}
      </p>
      <div dangerouslySetInnerHTML={{ __html: node.description || '' }} />
      <AudioPlayer {...{ length, idx, id }} />
    </div>
  )
}

export default ({ data, location }) => {
  const { allRssEntry } = data

  return (
    <Layout {...{ location }}>
      <SEO {...{ title }} />
      <div>
        <img src='/uploads/fairway-tales-header-mobile.png' className='img-fluid w-100 d-sm-none' alt='' />
        <img src='/uploads/fairway-tales-header.png' className='img-fluid w-100 d-none d-sm-block' alt='' />
        <section className='py-4'>
          <div className='container'>
            <h2 className='text-navy pb-3'>{title}</h2>
            <div className='row m-0'>
              <div className='col-md-12 col-lg-6'>
                <p className='text-muted'>
                  Fairway Tales is a PGA of America podcast committed to telling some of the most powerful stories surrounding girls and women in golf. We’re here to highlight the best, brightest and most unique personalities in the game—while showing that golf can be whatever we want it to be. Let these passion-filled and fun-driven narratives guide you, inspire you and entertain you!
                </p>
              </div>
              <div className='col-md-12 col-lg-6'>
                <div className='card p-4 box-shadow-none'>
                  <p className='text-muted text-center fw-500'>SUBSCRIBE TO FAIRWAY TALES</p>
                  <LinkWrapper>
                    <SubscribeLink href='https://itunes.apple.com/us/podcast/fairway-tales/id1457246814?mt=2' target='_blank'><img src='/assets/images/podcast/apple.png' alt='apple' /></SubscribeLink>
                    <SubscribeLink href='https://open.spotify.com/show/0XaUffsRU2FF8IxleFk1xS' target='_blank'><img src='/assets/images/podcast/spotify.png' alt='spotify' /></SubscribeLink>
                    <SubscribeLink href='https://soundcloud.com/fairwaytales/sets/fairway-tales' target='_blank'><img src='/assets/images/podcast/soundcloud.png' alt='soundcloud' /></SubscribeLink>
                  </LinkWrapper>
                </div>
              </div>
            </div>
            <hr />
            <section className='pt-2 pb-0'>
              <div className='container'>
                {allRssEntry.edges.map((rssData, idx) => <Card data={rssData} key={idx} idx={idx} length={allRssEntry.edges.length} />)}
              </div>
            </section>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allRssEntry {
      edges {
        node {
          id
          title
          description
          link
          date
        }
      }
    }
  }
`
